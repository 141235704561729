import {makeUUID} from './dom';

export interface INodeMap {
  [id: string]: IBlockNode;
}


export enum BlockHtmlType {
  DIV = 0,
  INPUT = 1,
  TEXTAREA = 2,
  UL = 3,
  LI = 4,
  BUTTON = 5,
  VIDEO = 6,

  CUSTOM = 100,
}

export interface IGlue {
  fdefs: {
    'remote': string; args: string[]
  }[]
}

export const ALLBLOCKS = [
  BlockHtmlType.DIV,
  BlockHtmlType.INPUT,
  BlockHtmlType.TEXTAREA,
  BlockHtmlType.UL,
  BlockHtmlType.LI,
  BlockHtmlType.BUTTON,
  BlockHtmlType.VIDEO,
  BlockHtmlType.CUSTOM
];

export function blockTag(block: BlockHtmlType, node: IBlockNode) {
  switch (block) {

    case BlockHtmlType.DIV:
      return 'div';

    case BlockHtmlType.INPUT:
      return 'input';

    case BlockHtmlType.TEXTAREA:
      return 'textarea';

    case BlockHtmlType.UL:
      return 'ul';

    case BlockHtmlType.LI:
      return 'li';

    case BlockHtmlType.BUTTON:
      return 'button';

    case BlockHtmlType.VIDEO:
      return 'video';

    case BlockHtmlType.CUSTOM:
      if (node && node.customHtmlType && node.customHtmlType.length > 0) {
        return node.customHtmlType;
      }
      return '???';
  }
}

export enum ControlType {
  Color,
  Thickness,
  Real,
  String
}

export enum InputType {
  Number,
  String,
  Object
}


export interface IBlockNode {
  readonly id: string;
  name?: string; // DEPRICATED
  className: string;
  text: string;

  contentHtml: string;
  isContentBlock: boolean;

  clickAction: string; // Click event handler
  enableAction: string; // Enable/Disable event handler
  classAction: string; // Add classes from this object

  repeatAction: string;
  repeatBinding: string; // For array for loops

  model: string;
  placeholder: string;

  htmlType: BlockHtmlType;
  customHtmlType: string;

  children: IBlockNode[];
  isRootNode: boolean;

  attrs: { [key: string]: string } | null;
}

export interface IBlockView {
  name: string;
  stylesheet: string;
  minWidth: number;
  maxWidth: number;
}

// Data required for object initialization
export interface Input {
  readonly id: string;
  name: string;  //must be a valid identifier
  description: string;
  type: InputType;
}

// Block settings
export interface ISetting {
  readonly id: string;
  name: string;
  description: string;
  required: boolean;
  defaultValueInitializor: string;
  controlType: ControlType;
  controlTypeParameters: any;
}


export interface IConfigSetting {
  referenceId: string;
  value: string;
}

export interface IExample {
  name: string;
  description: string;

  values: IConfigSetting[];
  inputs: IConfigSetting[];
}

export interface IOutputData extends Input {

}

export interface IBlock {
  readonly id: number; // must be a valid random css identifier
  name: string;
  root: IBlockNode;
  stylesheet: string;
  js: string;
  backend: string;
  views: IBlockView[];
  variables: Input[];
  settings: ISetting[];
  outputs: IOutputData[];
  examples: IExample[];
}

export interface IBlockValidationResult {
  ok: boolean;

  error?: string | null | undefined;
  errorSubject?: string | null | undefined;
  errorPath?: string | null | undefined;
}

export function makeSafeForCSS(name: string): string {
  return name.replace(/ /g, '_').replace(/[^a-zA-Z_]/g, '').toLowerCase();
}

export function makeDefaultNode(children: IBlockNode[] = []): IBlockNode {
  return {
    id: makeUUID(),
    text: '',
    customHtmlType: '',
    contentHtml: '',
    isContentBlock: false,
    htmlType: BlockHtmlType.DIV,
    className: '',
    isRootNode: false,
    classAction: '',
    repeatBinding: '', // For array for loops
    repeatAction: '',
    enableAction: '',
    clickAction: '',
    model: '',
    placeholder: '',
    children: children,
    attrs: {}
  };
}

export function makeDefaultBlock(blockId: number, name: string): IBlock {

  return {
    id: blockId,
    name: name,
    backend: '',
    js: 'function init(){}',
    stylesheet: '',
    root: {
      id: makeUUID(),
      name: 'root node',
      contentHtml: '',
      text: '',
      isContentBlock: false,
      htmlType: BlockHtmlType.DIV,
      className: 'root',
      isRootNode: true,


      enableAction: '',
      clickAction: '',
      repeatBinding: '', // For array for loops
      repeatAction: '',
      classAction: '',
      model: '',
      placeholder: '',
      customHtmlType: '',
      children: [],
      attrs: {},
    },
    views: [],
    variables: [],
    settings: [],
    outputs: [],
    examples: [],
  };
}

export function traverse(node: IBlockNode, collection: INodeMap = {}) {

  collection[node.id] = node;
  for (let child of node.children) {
    traverse(child, collection);
  }
  return collection;
}

export function validateBlock(block: IBlock): IBlockValidationResult {
  return {ok: true};
}

