let vueInited = false;

import {Subject} from 'rxjs';

import Vue from 'vue';
import Vuetify from 'vuetify';

import CKEditor from '@ckeditor/ckeditor5-vue';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';

export const vueErrors = new Subject<string>();

export interface IVueEditorConfig {
	controller: any;
	options: any;
}

export function getVueEditorConfig(): IVueEditorConfig {
	return {
		controller: BalloonEditor,
		options: {}
	};
}

export function instantiateVue(id: string, data: any, methods: any, watch: any, computed: any): Vue {

	if (!vueInited) {
		vueInited = true;

		Vue.use(CKEditor);
		Vue.use(Vuetify);

		Vue.config.errorHandler = err => {
			const ferr = err.name + '\n' + err.message;
			vueErrors.next(ferr);
		};

		Vue.config.warnHandler = err => {
			vueErrors.next(err);
		};
	}

	return new Vue({
		el: '#' + id,
		data: data,
		methods: methods,
		watch: watch,
		computed: computed
	});
}
