import {Component, ElementRef, NgZone, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RenderView} from '../common/renderer';
import {IBlock, IConfigSetting, IGlue} from '../common/blocks';
import {BlockBackend} from '../constants';


function checkNodeClassName(node: Element, name: string) {
	return node.classList.contains(name);
}

export function findChildWithClassName(node: Element, name: string) {

	if (checkNodeClassName(node, name)) {
		return node;
	}

	for (let i = 0; i < node.children.length; ++i) {
		const child = node.children[i];
		const result = findChildWithClassName(child, name);
		if (result != null) {
			return result;
		}
	}

	return null;
}

interface IServerData {
	block: IBlock,
	glue: IGlue
	inputs: IConfigSetting[],
	settings: IConfigSetting[]
}

interface IPayload<T> {
	data: T | null
	ok: boolean;
	error: string;
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {

	loading: boolean = true;
	error: boolean = false;

	private readonly _rv: RenderView = null;

	constructor(
		private readonly elem: ElementRef,
		private readonly zone: NgZone,
		private readonly http: HttpClient) {
		this._rv = new RenderView(elem, zone, http);
	}

	async getData(sessionId: number): Promise<IServerData> {

		const data = await (this.http.post(BlockBackend, {
			'action': 'block-session-data',
			'sessionId': sessionId
		}).toPromise()) as IPayload<IServerData>;

		if (!data.ok) {
			console.error(data.error);
			this.error = true;
		}

		console.log(data);
		data.data.glue = JSON.parse(data.data.glue as any as string || '');

		if (!data.data.settings) {
			data.data.settings = [];
		}

		if (!data.data.inputs) {
			data.data.inputs = [];
		}

		return data.data;
	}

	async ngOnInit() {
		const urlParams = new URLSearchParams(window.location.search);
		const sessionId: number = parseInt(urlParams.get('session'));

		if (sessionId) {

			const data = await this.getData(sessionId);

			this._rv.setSession(sessionId);
			this._rv.setGlue(data.glue);

			const target = findChildWithClassName(this.elem.nativeElement, 'render-container');
			target.innerHTML = await this._rv.render(data.block, data.settings);

			setTimeout(async () => {
				await this._rv.runApplication(data.block, data.inputs, data.settings);
				this.loading = false;
			});
		} else {
			this.error = true;
			this.loading = false;
		}

	}

	get rpcInProgress(): boolean {
		return this._rv.rpcInProgress;
	}
}
