import {blockTag, IBlock, IBlockNode, InputType, IGlue, traverse, IConfigSetting} from './blocks';

import {ElementRef, NgZone} from '@angular/core';
import {Subscription} from 'rxjs';
import * as less from 'less';

import {getVueEditorConfig, instantiateVue, vueErrors} from './vue';
import {HttpClient} from '@angular/common/http';
import {RPCBackend} from '../constants';

const selfClosingTags = {'input': true};

function makeReandomIdentifier() {
	const alphabet = 'abcdefghjklmnpqrstuvwxyz';
	let text = '';
	const n = alphabet.length;
	for (let i = 0; i < 8; i++) {
		text += alphabet.charAt(Math.floor(Math.random() * n));
	}
	return text;
}

function getHashCode(str: string): number {

	let hash = 0, i, chr;

	if (str.length === 0) {
		return hash;
	}

	for (i = 0; i < str.length; i++) {
		chr = str.charCodeAt(i);
		hash = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32bit integer
	}

	return hash;
}

function arr(passed_arguments) {
	const array = Array(passed_arguments.length);
	for (let i = 0; i < passed_arguments.length; ++i) {
		array[i] = passed_arguments[i];
	}
	return array;
}

function encodeAttribute(name: string): string {
	return `${name}`;
}

function makeIndent(depth: number): string {
	let indent = '\n';
	for (let i = 0; i < depth + 1; ++i) {
		indent += '    ';
	}
	return indent;
}

function getTag(block: IBlockNode): [string, boolean] {
	const tag = blockTag(block.htmlType, block);
	return [tag, selfClosingTags.hasOwnProperty(tag)];
}

function validExpression(action: string): boolean {
	return action && action.trim().length > 0;
}

function buildTag(block: IBlockNode, depth: number, id?: string): string {

	let [tag, selfClosing] = getTag(block);
	let attributes: { [tag: string]: string } = {};

	if (block.isContentBlock && tag !== 'input') {
		[tag, selfClosing] = ['ckeditor', false];
		attributes[':editor'] = 'UPWINTERNAL.EditorController';
		attributes[':config'] = 'UPWINTERNAL.EditorConfig';
		attributes['v-model'] = 'NODE(\'' + block.id + '\').contentHtml';
	}

	let html = '<' + tag;

	for (let attributeKey in block.attrs) {
		attributes[attributeKey] = block.attrs[attributeKey];
	}


	if (block.className) {
		attributes['class'] = encodeAttribute(block.className);
	}

	if (id) {
		attributes['id'] = encodeAttribute(id);
	}

	if (validExpression(block.repeatBinding) && validExpression(block.repeatBinding)) {
		attributes['v-for'] = `${block.repeatBinding} in ${block.repeatAction}`;
	}

	if (validExpression(block.enableAction)) {
		attributes['v-if'] = `${block.enableAction}`;
	}

	if (validExpression(block.clickAction)) {
		attributes['v-on:click'] = `${block.clickAction}`;
	}

	if (validExpression(block.classAction)) {
		attributes['v-bind:class'] = block.classAction;
	}

	if (validExpression(block.placeholder)) {
		attributes['placeholder'] = block.placeholder;
	}

	if (validExpression(block.model)) {
		attributes['v-model'] = block.model;
	}

	for (let attribute in attributes) {
		const value = attributes[attribute];
		html += ` ${attribute}="${value}"`;
	}


	if (!selfClosing) {
		html += '>';
		const indent = makeIndent(depth);

		if (block.text && block.text.length > 0) {
			html += indent + block.text;
		}

		for (let child of block.children) {
			html += indent + buildTag(child, depth + 1);
		}

		const ondent = makeIndent(depth - 1);
		html += ondent + '</' + tag + '>';

	} else {
		html += '/>';
	}

	return html;
}


export function buildHtml(root: IBlockNode, id: string): string {
	return `<v-app id=${id}>${buildTag(root, 0)}</v-app>`;
}


export class RenderView {

	private _css: string = null;
	private _cssHash: number = null;

	private _destroyed: boolean = false;
	private _styleElement: Node = null;
	private _rpcInFlightCounter = 0;
	private _vue: any;

	private _sessionId: number | null;
	private _glue: IGlue | null;
	private _targetId: string;

	private _vueErrorSubscription: Subscription;



	constructor(
		private readonly elem: ElementRef,
		private readonly zone: NgZone,
		private readonly http: HttpClient) {
		this._vueErrorSubscription = vueErrors.subscribe((err) => {
			this.onError('VUE', err);
		});
	}

	get rpcInProgress() {
		return this._rpcInFlightCounter > 0;
	}

	onError(source, error) {
		console.error(source, error);
	}

	async initSession(): Promise<boolean> {
		return false;
	}

	private static generateLess(block: IBlock, settings: any) {

		const lessVariables = [];

		if (settings) {

			for (let setting in settings) {
				const varname = setting;
				const varvalue = settings[setting] || '';
				const code = '@' + varname + ':' + varvalue;
				lessVariables.push(code);
			}
		}
		
		const vars = lessVariables.join(';\n');
		const source = vars + ';\n' + block.stylesheet;

		return `.block${block.id} {${source}}`;
	}

	private generateServerStub() {

		const that = this;

		const server = {};
		if (this.haveGlue) {

			for (let fdef of this._glue.fdefs) {
				server[fdef.remote] = function() {

					const params = arr(arguments);
					return new Promise(async (resolve, reject) => {
						try {

							if (!that.haveSession) {
								if (!await that.initSession()) {
									that.onError('VUE', 'No server');
									return;
								}
							}

							if (params.length != fdef.args.length) {
								reject('Need params: ' + fdef.args.join(', '));
								return;
							}


							const result = await that.zone.run(async () => {
								try {
									that._rpcInFlightCounter += 1;
									return await that.sessionRPC(that._sessionId, fdef.remote, params);
								} finally {
									that._rpcInFlightCounter -= 1;
								}
							});

							resolve(result);
						} catch (e) {
							reject(e);
						}
					});
				};
			}
		}

		return server;
	}

	private async postAny(backend: string, action: string, payload: any = {}): Promise<any> {

		const signedPayload = {
			'userId': 'NOT SET',
			'action': action,
			...payload
		};

		const result = this.http.post(backend, signedPayload).toPromise();

		return new Promise<any>(async r => {

			let error = null;
			try {
				const response = await result as any;
				if (response.ok) {
					r(response.data);
				} else {
					error = response.error;
				}
			} catch (e) {
				error = e;
			}

			if (error != null) {
				console.error(error);
			}
		});
	}

	async sessionRPC(sessionId: number, fn: string, params: any[]): Promise<any> {
		return await this.postAny(RPCBackend, fn, {
			'sessionId': sessionId,
			'params': params
		});
	}

	private initScope(block: IBlock, inputs: IConfigSetting[], settings: IConfigSetting[]) {
		const inputMap = {};
		const settingsMap = {};

		for (let input of inputs) {
			const val = block.variables.find(v => v.id === input.referenceId);

			if (val) {
				if (val.type == InputType.Object) {
					try {
						inputMap[val.name] = JSON.parse(input.value);
					} catch (e) {
						inputMap[val.name] = {};
					}
				}

				if (val.type == InputType.Number) {
					try {
						inputMap[val.name] = parseFloat(input.value);
					} catch (e) {
						inputMap[val.name] = 0;
					}
				}

				if (val.type == InputType.String) {
					try {
						inputMap[val.name] = input.value;
					} catch (e) {
						inputMap[val.name] = '';
					}
				}
			}
		}

		for (let setting of settings) {
			const val = block.settings.find(v => v.id === setting.referenceId);
			if (val) {
				settingsMap[val.name] = setting.value;
			}
		}

		return [inputMap, settingsMap];
	}

	private async generateStyles(block: IBlock, settings: any) {
		try {

			const lessCode = RenderView.generateLess(block, settings);

			const hash = getHashCode(lessCode);

			if (this._cssHash !== hash) {
				const compilation = await less.render(lessCode);
				this._css = compilation.css;
				this._cssHash = hash;
			}

			const head = document.head || document.getElementsByTagName('head')[0];

			if (this._styleElement) {
				head.removeChild(this._styleElement);
			}

			const style = document.createElement('style');
			style.appendChild(document.createTextNode(this._css));
			head.appendChild(style);
			this._styleElement = style;

		} catch (e) {
			this.onError('LESS', e);
		}
	}

	setSession(sessionId: number) {
		this._sessionId = sessionId;
	}

	setGlue(glue: IGlue) {
		this._glue = glue;
	}

	async render(block: IBlock, settings: any) {
		await this.generateStyles(block, settings);
		this._targetId = makeReandomIdentifier();
		return buildHtml(block.root, this._targetId);
	}

	runApplication(block: IBlock, inputs: any, settings: any) {

		if (this._vue) {
			this._vue.$destroy();
		}

		const host = this.elem.nativeElement as HTMLDivElement;
		host.classList.add(`block${block.id}`);

		const server = this.generateServerStub();

		if (this._destroyed) {
			return;
		}

		if (!document.getElementById(this._targetId)) {
			return;
		}

		const js = block.js;

		const scope = {
			'inputs': inputs,
			'settings': settings
		};


		let jss = null;
		try {
			jss = Function('scope', 'server',
				js + ';\n' +
				'var dataInternalDef = {};' +
				'try{dataInternalDef=data;}catch(e){}' +
				'var initInternalDef=function(){};' +
				'try{initInternalDef=init;}catch(e){}' +
				'var methodsInternalDef = {};' +
				'try{methodsInternalDef=methods;}catch(e){}' +
				'var watchInternalDef = {};' +
				'try{watchInternalDef=watch;}catch(e){}' +
				'var computedInternalDef = {};' +
				'try{computedInternalDef=computed;}catch(e){}' +
				'return {\'computed\':computedInternalDef,\'watch\':watchInternalDef,\'methods\':methodsInternalDef, \'data\':dataInternalDef, \'init\':initInternalDef}')(scope, server);
		} catch (e) {
			this.onError('JAVASCRIPT', e);
		}

		if (jss == null) {
			return;
		}

		const methods = jss['methods'];
		const data = jss['data'];
		const init = jss['init'];
		const watch = jss["watch"];
		const computed = jss["computed"];

		let initOk = true;
		try {
			const result = init();

			if (result && result.then) {
				result.catch((e) => {
					this.onError('JAVASCRIPT', e);
				});
			}
		} catch (e) {
			this.onError('JAVASCRIPT', e);
			initOk = false;
		}

		if (!initOk) {
			return;
		}

		const vueEditorConfig = getVueEditorConfig();

		data['UPWINTERNAL'] = {
			'EditorController': vueEditorConfig.controller,
			'EditorConfig': vueEditorConfig.options
		};

		data['scope'] = scope;

		const nodeMap = traverse(block.root);
		methods['NODE'] = function(nodeId) {
			return nodeMap[nodeId];
		};

		this._vue = instantiateVue(this._targetId, data, methods, watch, computed);
	}

	destroy() {
		this._destroyed = true;
		this._vueErrorSubscription.unsubscribe();
	}

	get haveSession() {
		return this._sessionId;
	}

	get haveGlue() {
		return this._glue;
	}

	clearSession() {
		this._sessionId = null;
	}
}
